import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layouts'
import Container from '../components/parts/Container'

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vw;
  align-items: center;
`

const Page404 = () => (
  <Layout>
    <Container>
      <NotFound>
        <h1>404 NotFound</h1>
        <p>
          <Link to="/">Return to top</Link>
        </p>
      </NotFound>
    </Container>
  </Layout>
)

export default Page404
